import { useEffect, useRef, useState } from "react";
// @mui
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Hidden from "@mui/material/Hidden";
import MuiLink from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Drawer, IconButton, List, ListItem, styled } from "@mui/material";
// components
import Image from "@/Components/image";
import { Link, usePage } from "@inertiajs/react";
import Iconify from "@/Components/iconify/Iconify";
import { useSnackbar } from "notistack";
import { CustomAvatar } from "@/Components/custom-avatar";
import useResponsive from "@/hooks/useResponsive";
import AccountPopover from "./AccountPopover";

export default function GuestHeader() {
    const theme = useTheme();
    const { auth, flash } = usePage().props;
    const { enqueueSnackbar } = useSnackbar();
    const contactInfoRef = useRef(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    useEffect(() => {
        if (flash.message) {
            enqueueSnackbar(flash.message, {
                variant: flash.type || "default",
            });
            flash.message = null;
        }
    }, [flash]);

    useEffect(() => {
        // Define the scroll event handler
        const handleScroll = () => {
            if (contactInfoRef.current) {
                const { top, bottom } =
                    contactInfoRef.current.getBoundingClientRect();
                const windowHeight =
                    window.innerHeight || document.documentElement.clientHeight;
                if (bottom < 0 || top > windowHeight) {
                    contactInfoRef.current.style.height = 0;
                } else {
                    contactInfoRef.current.style.height = "40px";
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setAnchorElUser(null);
    };

    const [drawerOpen, setDrawerOpen] = useState(false);
    const isMobile = useResponsive("down", "sm");
    const isTablet = useResponsive("down", "md");

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "white",
                    transition: "ease-in-out 250ms height",
                }}
                ref={contactInfoRef}
            >
                <Container
                    maxWidth="lg"
                    sx={{ display: "flex", paddingTop: 1, paddingBottom: 1 }}
                >
                    <Box sx={{ display: "inline-flex", gap: 2 }}>
                        <Stack direction="row" alignItems="center" spacing={3}>
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Box sx={{ paddingTop: "1px" }}>
                                    <Iconify
                                        icon="mdi:envelope-outline"
                                        sx={{ color: "#437099" }}
                                    />
                                </Box>
                                <Typography variant="body2">
                                    <MuiLink
                                        href="mailto:pinoycare1@gmail.com"
                                        color="inherit"
                                        underline="none"
                                    >
                                        join@upcareph.com
                                    </MuiLink>
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Box sx={{ paddingTop: "1px" }}>
                                    <Iconify
                                        icon="bi:telephone"
                                        sx={{ color: "#437099" }}
                                    />
                                </Box>
                                <Typography variant="body2">
                                    09092603898
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                    <Box flexGrow={1} />
                    <Hidden smDown>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Iconify
                                icon="mdi:twitter"
                                sx={{ color: "#437099" }}
                            />
                            <Iconify
                                icon="ic:baseline-facebook"
                                sx={{ color: "#437099" }}
                            />
                            <Iconify
                                icon="mdi:instagram"
                                sx={{ color: "#437099" }}
                            />
                            <Iconify
                                icon="mdi:linkedin"
                                sx={{ color: "#437099" }}
                            />
                        </Stack>
                    </Hidden>
                </Container>
            </Box>

            <Box
                sx={{
                    paddingTop: "8px",
                    width: "100%",
                    paddingBottom: "8px",
                    position: "fixed",
                    boxShadow: "0px 2px 15px rgba(25, 119, 204, 0.1);",
                    backgroundColor: "white",
                    zIndex: 1000,
                }}
            >
                <Container
                    maxWidth="xl"
                    sx={{
                        display: "flex",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                        px: 2,
                    }}
                >
                    <>
                        <MuiLink
                            component={Link}
                            href="/"
                            style={{ textDecoration: "none" }}
                        >
                            <Image
                                src="/assets/logo/upcare-logo.png"
                                sx={{ width: 100 }}
                            />
                        </MuiLink>
                        <Box flexGrow={1} />
                        <Stack
                            className="navbar"
                            direction="row"
                            alignItems="center"
                            spacing={3}
                            fontWeight={600}
                        >
                            <Stack direction={"row"}>
                                {/* {auth.user !== null && <AccountPopover />} */}
                                <Stack>
                                    <IconButton
                                        onClick={handleDrawerOpen}
                                        sx={{
                                            display: "block",
                                            [theme.breakpoints.up("md")]: {
                                                display: "none", // Hide the icon on larger screens
                                            },
                                        }}
                                    >
                                        <Iconify
                                            icon="material-symbols:menu"
                                            sx={{
                                                height: "32px",
                                                width: "32px",
                                                color: "#012970",
                                            }}
                                        />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Hidden mdDown>
                                <MuiLink
                                    href={auth.user != null ? "/home" : "/"}
                                    underline="none"
                                    className="nav-link scrollto"
                                >
                                    Home
                                </MuiLink>
                                <MuiLink
                                    href="/feeds"
                                    underline="none"
                                    className="nav-link scrollto"
                                >
                                    Feeds
                                </MuiLink>
                                <MuiLink
                                    href={route("job.jobList")}
                                    underline="none"
                                    className="nav-link scrollto "
                                >
                                    Find Jobs
                                </MuiLink>
                            </Hidden>
                            {auth.user !== null ? (
                                <>
                                    <MuiLink
                                        underline="none"
                                        onClick={handleUserMenu}
                                        className="nav-muiLink scrollto"
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Box>
                                                <CustomAvatar
                                                    src={auth.user?.profile}
                                                    name={auth.user?.fullname}
                                                    alt={auth.user?.fullname}
                                                />
                                            </Box>
                                            <Box>
                                                <Stack
                                                    direction="row"
                                                    spacing={0.5}
                                                >
                                                    {auth.user.firstname}{" "}
                                                    <Iconify icon="mdi:chevron-down" />
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </MuiLink>
                                    <Menu
                                        anchorEl={anchorElUser}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleUserMenuClose}
                                        PaperProps={{
                                            style: {
                                                width: "240px",
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                gap: "10px",
                                                padding: 0,
                                                paddingTop: 1,
                                            }}
                                            disabled
                                        >
                                            <Typography>
                                                {auth.user.fullname}
                                            </Typography>
                                        </MenuItem>
                                        {auth.user?.profession && (
                                            <MenuItem
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    gap: "10px",
                                                    padding: 0,
                                                    paddingBottom: 1,
                                                }}
                                                disabled
                                            >
                                                <Typography>
                                                    {auth.user?.profession}
                                                </Typography>
                                            </MenuItem>
                                        )}
                                        <MenuItem
                                            onClick={handleUserMenuClose}
                                            sx={{
                                                borderBottom: "1px solid",
                                                borderTop: "1px solid",
                                                borderColor: "#d2d2d2",
                                                padding: 1,
                                            }}
                                        >
                                            <Stack
                                                component={Link}
                                                href={route("user.profile")}
                                                sx={{
                                                    width: 1,
                                                    textDecoration: "none",
                                                    color: (theme) =>
                                                        theme.palette.text
                                                            .primary,
                                                }}
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Iconify icon="mingcute:user-2-line" />
                                                <Typography>
                                                    My Profile
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleUserMenuClose}
                                            sx={{
                                                borderBottom: "1px solid",
                                                borderColor: "#d2d2d2",
                                                padding: 1,
                                            }}
                                        >
                                            <Stack direction="row" spacing={1}>
                                                <Iconify icon="ph:question-light" />
                                                <Typography>
                                                    Need Help?
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                        <MenuItem
                                            onClick={handleUserMenuClose}
                                            sx={{ padding: 1 }}
                                        >
                                            <Stack
                                                component={Link}
                                                method="post"
                                                href={route("logout")}
                                                sx={{
                                                    width: 1,
                                                    textDecoration: "none",
                                                    color: (theme) =>
                                                        theme.palette.text
                                                            .primary,
                                                }}
                                                direction="row"
                                                spacing={1}
                                            >
                                                <Iconify icon="mdi-light:logout" />
                                                <Typography>Log Out</Typography>
                                            </Stack>
                                        </MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <Hidden mdDown>
                                    <Stack direction={"row"} spacing={1}>
                                        <Button
                                            className="login-btn"
                                            variant="contained"
                                            LinkComponent={Link}
                                            href={route("login")}
                                        >
                                            <Box
                                                component="span"
                                                sx={{ color: "white" }}
                                            >
                                                {" "}
                                                Login
                                            </Box>
                                        </Button>
                                        <Button
                                            className="login-btn"
                                            variant="outlined"
                                            LinkComponent={Link}
                                            href={route("register")}
                                        >
                                            <Box component="span"> Sign up</Box>
                                        </Button>
                                    </Stack>
                                </Hidden>
                            )}
                        </Stack>

                        <Drawer
                            anchor="left"
                            open={drawerOpen}
                            onClose={handleDrawerClose}
                            PaperProps={{
                                sx: {
                                    width: "60%", // Set the Drawer width to 100% for full width
                                },
                            }}
                        >
                            <Stack
                                direction="column"
                                alignItems="center"
                                sx={{ padding: "16px" }}
                            >
                                <IconButton
                                    onClick={handleDrawerClose}
                                    sx={{
                                        alignSelf: "flex-end",
                                        paddingRight: "30px",
                                    }}
                                >
                                    <Iconify
                                        icon="zondicons:close-outline"
                                        sx={{
                                            width: "24px",
                                            height: "24px",
                                        }}
                                    />
                                </IconButton>
                                <List className="navbar" sx={{ color: "gray" }}>
                                    <ListItem>
                                        <MuiLink
                                            href={
                                                auth.user != null
                                                    ? "/home"
                                                    : "/"
                                            }
                                            underline="none"
                                            className="nav-link scrollto"
                                        >
                                            Home
                                        </MuiLink>
                                    </ListItem>
                                    <ListItem>
                                        <MuiLink
                                            href="/feeds"
                                            underline="none"
                                            className="nav-link scrollto"
                                        >
                                            Feeds
                                        </MuiLink>
                                    </ListItem>
                                    <ListItem>
                                        <MuiLink
                                            href={route("job.jobList")}
                                            underline="none"
                                            className="nav-link scrollto "
                                        >
                                            Find Jobs
                                        </MuiLink>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            className="login-btn"
                                            variant="contained"
                                            LinkComponent={Link}
                                            href={route("login")}
                                        >
                                            <Box
                                                component="span"
                                                sx={{ color: "white" }}
                                            >
                                                {" "}
                                                Login
                                            </Box>
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            className="login-btn"
                                            variant="outlined"
                                            LinkComponent={Link}
                                            href={route("register")}
                                        >
                                            <Box component="span"> Sign up</Box>
                                        </Button>
                                    </ListItem>
                                </List>
                            </Stack>
                        </Drawer>
                    </>
                </Container>
            </Box>
        </>
    );
}
